<template>
  <v-container id="issue-detail" fluid tag="section">
    <v-row justify="center">
      <dialog-message
        :dialog_message="dialog_message"
        :message="message"
      ></dialog-message>
      <v-col cols="12" md="10">
        <base-material-card>
          <template v-slot:heading>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
              v-if="!createIssue"
            >
              {{ item.title }}
            </div>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
              v-if="createIssue"
            >
              {{ $t("issue.createIssue") }}
            </div>
          </template>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="py-0">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    class="purple-input"
                    :label="$t('issue.meeting')"
                    v-model="meeting.title"
                    readonly
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="purple-input"
                    :label="$t('meeting.meeting_time')"
                    v-model="meeting_time"
                    readonly
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="purple-input"
                    :label="$t('meeting.urban_renewal')"
                    v-model="meeting.urban_renewal.name"
                    readonly
                  />
                </v-col>
                <v-col cols="12" v-if="createIssue">
                  <v-text-field
                    :label="$t('issue.title')"
                    v-model="item.title"
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-radio-group
                    v-model="item.hide_name"
                    :label="$t('issue.if_hide_name')"
                    row
                  >
                    <v-radio
                      :label="$t('issue.not_hide_name')"
                      :value="false"
                    ></v-radio>
                    <v-radio
                      :label="$t('issue.hide_name')"
                      :value="true"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                    color="secondary"
                    dark
                    class="mx-2"
                    @click="importOwners()"
                  >
                    <v-icon left>mdi-account-edit</v-icon>
                    {{ $t("owner.importOwners") }}
                  </v-btn>
                  <!-- <v-btn
                    color="primary"
                    dark
                    class="mx-2"
                    @click="createOptions()"
                  >
                    <v-icon left>mdi-pencil-plus</v-icon>
                    {{ $t("issue.createOptions") }}
                  </v-btn> -->
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="options"
                    :items-per-page="5"
                    :loading="loading"
                    multi-sort
                  >
                    <template v-slot:item.index="{ item }">
                      <v-flex>
                        <v-text-field v-model="item.index" v-if="!item.is_removed" readonly></v-text-field>
                      </v-flex>
                    </template>

                    <template v-slot:item.option_name="{ item }">
                      <v-flex>
                        <v-text-field v-model="item.option_name"></v-text-field>
                      </v-flex>
                    </template>
                    <!-- <template v-slot:item.option_priority="{ item }">
                      <v-flex>
                        <v-text-field
                          v-model="item.option_priority"
                        ></v-text-field>
                      </v-flex>
                    </template> -->
                    <template v-slot:item.precedence="{ item }">
                      <v-flex>
                        <v-checkbox
                          v-model="item.precedence"
                        ></v-checkbox>
                      </v-flex>
                    </template>
                    <template v-slot:item.is_removed="{ item }">
                      <span v-if="!item.is_removed">{{
                        $t("option.active")
                      }}</span>
                      <span v-if="item.is_removed">{{
                        $t("option.delete")
                      }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-flex>
                        <v-btn
                          color="primary"
                          class="mx-2"
                          @click="createOptions(item)"
                          small
                          >
                          <v-icon left>mdi-pencil-plus</v-icon>{{ $t("issue.createOptions") }}
                        </v-btn>

                        <v-btn
                          v-if="item.id&&!item.is_removed"
                          color="purple"
                          class="mx-2"
                          @click="deactiveOptions(item)"
                          small
                          >{{ $t("option.deactive") }}</v-btn
                        >
                        <v-btn
                          v-if="item.is_removed"
                          color="orange"
                          class="mx-2"
                          @click="recoverOptions(item)"
                          small
                          >{{ $t("common.recover") }}</v-btn
                        >
                        <v-btn
                          v-if="!item.id"
                          color="red"
                          class="mx-2"
                          @click="deleteOptions(item)"
                          small
                          >{{ $t("common.delete") }}</v-btn
                        >
                      </v-flex>
                    </template>
                    <template slot="no-data">
                      <div>
                        {{ $t('common.noData') }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="item.max_elect_count"
                    :label="$t('issue.max_elect_count')"
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="item.positive_count"
                    :label="$t('issue.positive_count')"
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="item.alternates_count"
                    :label="$t('issue.alternates_count')"
                    :rules="fieldRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.pass_land_area_numerator"
                    :label="$t('issue.pass_land_area_numerator')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.pass_land_area_denominator"
                    :label="$t('issue.pass_land_area_denominator')"
                    ><v-icon slot="prepend">
                      mdi-slash-forward
                    </v-icon></v-text-field
                  >
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.pass_building_area_numerator"
                    :label="$t('issue.pass_building_area_numerator')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.pass_building_area_denominator"
                    :label="$t('issue.pass_building_area_denominator')"
                    ><v-icon slot="prepend">
                      mdi-slash-forward
                    </v-icon></v-text-field
                  >
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.pass_owner_num_numerator"
                    :label="$t('issue.pass_owner_num_numerator')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.pass_owner_num_denominator"
                    :label="$t('issue.pass_owner_num_denominator')"
                    ><v-icon slot="prepend">
                      mdi-slash-forward
                    </v-icon></v-text-field
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.memo"
                    :label="$t('common.memo')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex">
                    <v-btn color="navy" v-if="!createIssue" @click="getTicket(item)">
                      <v-icon left>mdi-download</v-icon>
                      {{ $t("vote.ticket") }}
                    </v-btn>
                    <v-btn color="aqua" class="ml-auto mr-2" @click="back(meeting_id)">{{
                      $t("common.back")
                    }}</v-btn>
                    <v-btn
                      v-if="!createIssue"
                      color="success"
                      class="mr-0"
                      @click="patchApi()"
                      >{{ $t("common.save") }}</v-btn
                    >
                    <v-btn
                      v-if="createIssue"
                      color="success"
                      class="mr-0"
                      :disabled="loading"
                      @click="save()"
                      >{{ $t("common.save") }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import CommonUtil from "@/utils/commonutil.js";

export default {
  data: (vm) => ({
    dialog_message: false,
    loading: false,
    totalItems: 0,
    createIssue: false,
    table_options: {},
    importOwner: false,
    options: [
      {
        option_name: "",
        is_removed: false,
        option_priority: 0,
        index: 1,
        precedence: false,
        id: null,
        owner_id: null
      },
      {
        option_name: "",
        is_removed: false,
        option_priority: 0,
        index: 2,
        precedence: false,
        id: null,
        owner_id: null
      },
    ],
    meeting: { name: null, urban_renewal: { name: null } },
    meeting_time: null,
    item: {
      title: null,
      max_elect_count: null,
      positive_count: null,
      alternates_count: null,
      hide_name: false,
      pass_owner_num_numerator: null,
      pass_owner_num_denominator: null,
      pass_land_area_numerator: null,
      pass_land_area_denominator: null,
      pass_building_area_numerator: null,
      pass_building_area_denominator: null,
      memo: null,
      is_director: false,
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("option.index"),
        value: "index",
      },
      {
        sortable: false,
        text: vm.$i18n.t("option.option_name"),
        value: "option_name",
      },
      // {
      //   // sortable: false,
      //   text: vm.$i18n.t("option.option_priority"),
      //   value: "option_priority",
      // },
      {
        sortable: false,
        text: vm.$i18n.t("option.precedence"),
        value: "precedence",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions",
      },
    ],
    message: {
      title: "",
      text: "",
    },
    valid: true,
    fieldRules: [(v) => !!v || vm.$i18n.t("common.missing")],
  }),
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.table_options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/issue/" + this.item_id + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          //   page: page,
          //   page_size: itemsPerPage,
          //   task: this.id
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getMeeting(){
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.table_options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/meeting/" + this.meeting_id + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.meeting = response.data;
          currentObj.meeting_time = currentObj.TransDate(currentObj.meeting.meeting_time);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let has_blank =this.checkOptionsBlank()
      if (has_blank == true){
        this.message.title = '錯誤訊息';
        this.message.text = '選項內容或數量有誤，請輸入正確選項資料';
        this.dialog_message = !this.dialog_message;
        return;
      }
      let options_valid =this.checkOptionsValid()
      if (options_valid == false){
        this.message.title = '錯誤訊息';
        this.message.text = '選項數量與正/備取數量不符，請輸入正確選項資料';
        this.dialog_message = !this.dialog_message;
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/issue/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data = {
        meeting_id: this.meeting.id,
        title: this.item.title,
        max_elect_count: this.item.max_elect_count,
        positive_count: this.item.positive_count,
        alternates_count: this.item.alternates_count,
        options: this.options,
        hide_name: this.item.hide_name,
        pass_owner_num_numerator: this.item.pass_owner_num_numerator,
        pass_owner_num_denominator: this.item.pass_owner_num_denominator,
        pass_land_area_numerator: this.item.pass_land_area_numerator,
        pass_land_area_denominator: this.item.pass_land_area_denominator,
        pass_building_area_numerator: this.item.pass_building_area_numerator,
        pass_building_area_denominator: this.item.pass_building_area_denominator,
        memo: this.item.memo,
        is_director: this.item.is_director
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.back(currentObj.meeting_id);
          alert("送出成功");
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    patchApi() {
      let has_blank =this.checkOptionsBlank()
      if (has_blank == true){
        this.message.title = '錯誤訊息';
        this.message.text = '選項內容或數量有誤，請輸入正確選項資料';
        this.dialog_message = !this.dialog_message;
        return;
      }
      let options_valid =this.checkOptionsValid()
      if (options_valid == false){
        this.message.title = '錯誤訊息';
        this.message.text = '選項數量與正/備取數量不符，請輸入正確選項資料';
        this.dialog_message = !this.dialog_message;
        return;
      }
      this.setOptionsPriority()
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/issue/" + this.item.id + "/";

      const jsonData = {
        meeting_id: this.meeting.id,
        title: this.item.title,
        max_elect_count: this.item.max_elect_count,
        positive_count: this.item.positive_count,
        alternates_count: this.item.alternates_count,
        options: this.options,
        importOwner: this.importOwner,
        hide_name: this.item.hide_name,
        pass_owner_num_numerator: this.item.pass_owner_num_numerator,
        pass_owner_num_denominator: this.item.pass_owner_num_denominator,
        pass_land_area_numerator: this.item.pass_land_area_numerator,
        pass_land_area_denominator: this.item.pass_land_area_denominator,
        pass_building_area_numerator: this.item.pass_building_area_numerator,
        pass_building_area_denominator: this.item.pass_building_area_denominator,
        memo: this.item.memo,
        is_director: this.item.is_director
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          alert("更新成功。");
          currentObj.back(currentObj.meeting_id);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
      this.dialog_form = false;
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.item = data.issue;
      this.meeting = data.issue.meeting;
      this.meeting_time = this.TransDate(this.meeting.meeting_time);
      this.totalItems = info.options.length;
      this.options = info.options;
      let option_index = 1
      this.options.forEach(element=>{
        element['index'] = option_index
        option_index +=1
        if(element.option_name ==''){
          element.option_name = ' '
        }
      })
    },
    back(meeting) {
      this.$router.push({
        name: "IssueList",
        query: {
          item: meeting,
        },
      });
    },
    createOptions(item) {
      this.changeIndex(item, 'create')
      let new_option = {
        option_name: "",
        is_removed: false,
        precedence: false,
        index: item.index+1,
        id: null,
        option_priority: 0,
        owner_id: null
      };
      this.options.splice(item.index, 0, new_option);
    },
    changeIndex(item, action){
      let target_index = item.index
      this.options.forEach(element=>{
        switch (action){
          case 'create':
            if (element.index >= target_index+1){
              element.index += 1
            }
            break;
          case 'remove':
            if (element.index > target_index){
              element.index -= 1
            }
            break;
          case 'recover':
            if ((element.index == target_index && element.id != item.id) || element.index > target_index){
              element.index += 1
            }
            break;
        }
      })
    },
    deactiveOptions(item) {
      item.is_removed = true;
      this.changeIndex(item, 'remove')
    },
    recoverOptions(item) {
      item.is_removed = false;
      this.changeIndex(item, 'recover')
    },
    deleteOptions(item) {
      if (this.options.length > 2) {
        let item_index = this.options.indexOf(item);
        this.options.splice(item_index, 1);
        this.changeIndex(item, 'remove')
      }
    },
    importOwners() {
      this.loading = true;
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/meeting/" +
        this.meeting.id +
        "/getOwners/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setOptions(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("import-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setOptions(data) {
      if(data.owners.length==0){
        this.message.title = this.$t("import-fail");
        this.message.text = "合法所有權人為0";
        this.dialog_message = !this.dialog_message;
        return;
      }
      this.item.is_director = true;
      this.importOwner = true;
      this.options = [];
      let option_index = 1;
      data.owners.forEach((item) => {
        let option_name = "";
        if (item.regno_no) {
          option_name = item.regno_no + "_" + item.owner_name;
        } else {
          option_name = item.owner_name;
        }
        let new_option = {
          index: option_index,
          option_name: option_name,
          is_removed: false,
          id: null,
          option_priority: 0,
          owner_id: item.id
        };
        this.options.push(new_option);
        option_index += 1;
      });
    },
    checkOptionsBlank(){
      let has_blank = false;
      if(this.options.length <2){
        has_blank = true;
      }
      this.options.forEach(element=>{
        if(element.option_name == ''){
          has_blank = true;
        }
      })
      return has_blank
    },
    checkOptionsValid(){
      let optionsValid = true;
      let option_count = this.options.length
      if (option_count < parseInt(this.item.positive_count) + parseInt(this.item.alternates_count)){
        optionsValid = false
      }
      return optionsValid
    },
    setOptionsPriority(){
      let option_sum = this.options.length;
      this.options.forEach(element=>{
        element.option_priority = option_sum
        option_sum -= 1
      })
    },
    getTicket() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/issue/" +
        this.item.id +
        "/getTicket/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: 'arraybuffer'
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          let blob = new Blob([response.data], {type: "application/octet-stream"});
          let objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          let date_str = CommonUtil.getDateString();
          let file_name = currentObj.item.meeting.urban_name + "_" + currentObj.item.meeting.title + "_" + currentObj.item.title + "投票單_"+ date_str +".docx"
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    }
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.IssueDetail"));
    this.meeting_id = this.$route.query.meeting;
    if (this.$route.query.item) {
      this.item_id = this.$route.query.item;
      this.getApi();
    } else {
      this.getMeeting();
      this.createIssue = true;
      this.totalItems = this.options.length;
      const { sortBy, sortDesc, page, itemsPerPage } = this.table_options;
    }
  },
};
</script>
